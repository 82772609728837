import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export type HeightStateTypes = 'open' | 'close';
/*
https://dev.to/lukaszsarzynski/own-expansion-panel-using-angular-animation-3k9d
*/

export const heightAnimation = trigger('animateHeight', [
  transition(':enter', [
    style({ height: 0, opacity: 0, overflow: 'hidden' }),
    animate('150ms ease-out', style({ height: '*', opacity: 1 })),
  ]),
  transition(':leave', [
    style({ overflow: 'hidden' }),
    animate('200ms ease-out', style({ height: 0, opacity: 0 })),
  ]),
]);

export const fadeInFromTopAnimation = trigger('fadeInFromTopAnimation', [
  state(
    'void',
    style({
      opacity: 0,
      transform: 'translateY(-20px)',
      height: 0,
    }),
  ),
  transition(':enter', [
    animate(
      '100ms ease-out',
      style({ opacity: 1, transform: 'translateY(0)', height: '*' }),
    ),
  ]),
  transition(':leave', [
    animate(
      '100ms ease-out',
      style({
        opacity: 0,
        transform: 'translateY(-20px)',
        height: 0,
      }),
    ),
  ]),
]);

export const fadeInAnimation = trigger('fadeInAnimation', [
  transition(':enter', [
    style({ opacity: 0, scale: 0.85 }),
    animate('100ms ease-out', style({ opacity: 1, scale: 1 })),
  ]),
  transition(':leave', [
    animate('100ms ease-out', style({ opacity: 0, scale: 0.85 })),
  ]),
]);

export const getHeightAnimation = (closedHeight = '0px') =>
  trigger('animateHeight', [
    state('close', style({ height: closedHeight, 'overflow-y': 'hidden' })),
    state('open', style({ height: '*', 'overflow-y': 'hidden' })),
    transition('open <=> close', animate('300ms ease-in-out')),
  ]);

export const getWidthAnimation = (closedWidth = '0px', openWidth = '*') =>
  trigger('animateWidth', [
    state('close', style({ width: closedWidth })),
    state('open', style({ width: openWidth })),
    transition('open <=> close', animate('300ms ease-in-out')),
  ]);

export const getHeightAnimationEnterLeave = (closedHeight = '0px') =>
  trigger('animateHeight', [
    transition('void => *', [
      style({ height: closedHeight }),
      animate(150, style({ height: '*' })),
    ]),
    transition('* => void', [animate(150, style({ height: closedHeight }))]),
  ]);
